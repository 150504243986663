var GblTop;
function GetVertOffset(srchStr)
{
    GblTop = $(srchStr).offset().top;
}
$(function () {
    if ($('body').hasClass('ppc-landing-page')) {
        GetVertOffset('.gray-banner');     // -- Sets GblTop
        $(window).scroll(function () {
            GetVertOffset('.gray-banner');
            var y = $(window).scrollTop();
            if (y >= GblTop) {
                $('.top-bar-cta-ppc').addClass('fixed-ppc').slideDown(500).fadeIn(125);
            } else {
                $('.top-bar-cta-ppc').removeClass('fixed-ppc').slideUp(500).fadeOut(125);
            }
        });
    }



    $('.accordion2:first-of-type i').addClass('flipped');
    $('.accordion1 .header, .accordion2 .header').on('click', function () {
        var body = $(this).next().next();
        var icon = $(this).next();

        body.slideToggle('500');
        icon.toggleClass('flipped');

    });


    $('[name="more-entities"]').on('click', function () {
            $(this).parent().hide();
            // $('.LLC-type').removeClass("medium-3");
            // $('.LLC-type').addClass("medium-6");
            // $('.corp-type').removeClass("medium-5");
            // $('.corp-type').addClass("medium-6");
            // $('.corp-type').removeClass('small-padding-right');
    });

    $('#close-menu-button').hide();
    // drop down nav menu
    $("#menu-button").click(function () {
        $(".nav-container").slideDown(500);
        // $(".top-sub-menu").addClass('fixed-top');
        $(".blue-req-menu").addClass('fixed-top-bar');
        $("#menu-button").fadeOut(250);
        $('#close-menu-button').fadeIn(500);
        $('body').addClass('stop-scrolling2');
        $('html, body').animate({scrollTop : 0},100);
        $('footer').hide();
        $('.search-bar').show();

    });


    $("#menu-text").click(function () {
        $(".nav-container").slideDown(500);
        // $(".top-sub-menu").addClass('fixed-top');
        $(".blue-req-menu").addClass('fixed-top-bar');
        $("#menu-text").fadeOut(250);
        $('#close-menu-button').fadeIn(500);
        $('body').addClass('stop-scrolling2');
        $('.body-container').addClass('stop-scrolling');
        $('html, body').animate({scrollTop : 0},100);
        $('footer').hide();
        $('.search-bar').show();
        // document.ontouchstart = function(e){ e.preventDefault(); }
    });


    $("#close-menu-button").click(function () {
        $(".nav-container").slideUp(500);
        // $(".top-sub-menu").removeClass('fixed-top');
        $(".blue-req-menu").removeClass('fixed-top-bar');
        $('#close-menu-button').fadeOut(250);
        // $("#menu-button").fadeIn(500);
        $("#menu-text").fadeIn(500);
        $('body').removeClass('stop-scrolling2');
        $('.body-container').addClass('stop-scrolling');
        $('footer').show();
        // document.ontouchstart = function(e){ return true; }
    });





    $("#exit-button").click(function () {
        $(".nav-container").slideUp(500);

    });


    // light up icons on focus
    $('#forgot-username').on('focusin', function () {
        $(this).prev().addClass('primary-color');
    });

    $('#forgot-username').on('focusout', function () {
        $(this).prev().removeClass('primary-color');
    });

    $('#login-username').on('focusin', function () {
        $(this).prev().addClass('primary-color');
    });

    $('#login-username').on('focusout', function () {
        $(this).prev().removeClass('primary-color');
    });

    $('#login-password').on('focusin', function () {
        $(this).prev().addClass('primary-color');
    });

    $('#login-password').on('focusout', function () {
        $(this).prev().removeClass('primary-color');
    });


    // search button
    $('.search-button-container').on('click', function () {
        $('.search-bar').slideToggle(250);
    });

    $('.mobile-search').on('click', function () {
        // $('.search-bar').slideToggle(250);
    });

    // more menu
   /* $('.more-menu').on('click', function() {
        $('.sub-menu').slideToggle(250);
        //$('.sub-menu').removeClass('hide-me');

    });*/



   $('.signup-menu').on('click', function () {
        // $('.signup-over').removeClass('hide-me');
   });

});


$(document).ready(function () {
   if ($('.basic-page').length || $('.sticky-side-bar').length ) {
        // code for right side forms
       var getScroll = function () {
           var b = document.body;
           var e = document.documentElement;
           return {
               left: parseFloat(window.pageXOffset || b.scrollLeft || e.scrollLeft),
               top: parseFloat(window.pageYOffset || b.scrollTop || e.scrollTop)
           };
       };

       // function getScroll() {
        // var b = document.body;
        // var e = document.documentElement;
        // return {
        // left: parseFloat( window.pageXOffset || b.scrollLeft || e.scrollLeft ),
        // top: parseFloat( window.pageYOffset || b.scrollTop || e.scrollTop )
        // };
        // }
        var myHeader = $('main');
        myHeader.data('position', myHeader.position());

       var myFooter = $('.footer');
           myFooter.data('position', myFooter.position());

        $(window).scroll(function () {
                var hPos = myHeader.data('position'), scroll = getScroll();
                if (hPos.top < scroll.top ) {
                    $('.sticky-side-bar').addClass('fixed');
                } else {
                    $('.sticky-side-bar').removeClass('fixed');
                }
        });


       $(window).scroll(function () {
           // Distance from top of document to top of footer.
           var topOfFooter = $('.footer').position().top;
           // Distance user has scrolled from top, adjusted to take in height of cta (600 pixels inc. padding).
           var scrollDistanceFromTopOfDoc = $(document).scrollTop() + 600;
           // Difference between the two.
           var scrollDistanceFromTopOfFooter = scrollDistanceFromTopOfDoc - topOfFooter;

           // If user has scrolled further than footer,
           // pull cta up using a negative margin.
           if (scrollDistanceFromTopOfDoc > topOfFooter) {
               $('.sticky-side-bar').css('margin-top',  0 - scrollDistanceFromTopOfFooter);
           } else {
               $('.sticky-side-bar').css('margin-top', 10);
           }
       });

           $(window).resize(function () {

                myHeader.data('position', myHeader.position());
                var hPos = myHeader.data('position'), scroll = getScroll();
                if (hPos.top < scroll.top ) {
                    $('.sticky-side-bar').addClass('fixed');
                } else {
                    $('.sticky-side-bar').removeClass('fixed');
                }

        });
    }

    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before('<div class="scroll-header">Swipe to view more content</div>');
    }
    scrollWrap();

    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table').each(function (index) {
            var table = $(this);
            var tableWidth = table.outerWidth();
            var parentWidth = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
            } else {
                // element doesn't overflow
                scrollHeader.hide();
            }
        })
    }

    function tableCheck(){

        if ($('div.scroll-wrapper table tbody tr td').is(':empty')) {
            $('div.scroll-wrapper table tbody tr td:not(:nth-of-type(1)):empty').css('background-color','#bebebe');
        }
    }

    tableCheck();

    $(window).on("resize", function () {
        handleScrollResize();
    });
    $(document).ready(function () {
        handleScrollResize();
    });

    const paymentFields = document.querySelectorAll('#first_name, #last_name, #cardNumber, #cvv, #exp-month, #exp-year');
    for (const field of paymentFields) {
        field.value = "";
    }

});